import React, { useEffect, useState } from "react";
import Column from "./Column";
import { headerColors } from "../../../Common/kanban";
import { useDispatch, useSelector } from "react-redux";
import {
  addClientProjectData,
  getClientProjects,
} from "../../../Feature/Client/projectSlice";
// import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";

const Board = () => {
  const [columns, setColumns] = useState({});
  const dispatch = useDispatch();
  // let { id } = useParams();
  const location = useLocation();
  const data = location.state?.channel_id;
  const { getClientProject, addClientProject, searchClientProject } =
    useSelector((state) => state.clientProject);

  const columnsFromBackend = {
    // eslint-disable-next-line
    ["Backlog"]: {
      name: "Backlog",
      items: getClientProject.Backlog ? getClientProject.Backlog.items : [],
    },
    // eslint-disable-next-line
    ["Script"]: {
      name: "Script",
      items: getClientProject.Script ? getClientProject.Script.items : [],
    },
    // eslint-disable-next-line
    ["Voice"]: {
      name: "Voice",
      items: getClientProject.Voice ? getClientProject.Voice.items : [],
    },
    // eslint-disable-next-line
    ["Video"]: {
      name: "Video",
      items: getClientProject.Video ? getClientProject.Video.items : [],
    },
    // eslint-disable-next-line
    ["Comp"]: {
      name: "Completed",
      items: getClientProject.Comp ? getClientProject.Comp.items : [],
    },
    // eslint-disable-next-line
    ["Revision"]: {
      name: "Revision",
      items: getClientProject.Revision ? getClientProject.Revision.items : [],
    },
    // eslint-disable-next-line
    ["Ready"]: {
      name: "Ready to upload",
      items: getClientProject.Ready ? getClientProject.Ready.items : [],
    },
    // eslint-disable-next-line
    ["Published"]: {
      name: "Published",
      items: getClientProject.Published ? getClientProject.Published.items : [],
    },
    // eslint-disable-next-line
    ["Archived"]: {
      name: "Archived",
      items: getClientProject.Archived ? getClientProject.Archived.items : [],
    },
  };

  //useEffects
  useEffect(() => {
    dispatch(getClientProjects(data));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (addClientProject) {
      dispatch(getClientProjects(data));
    }
    // eslint-disable-next-line
  }, [addClientProject]);

  useEffect(() => {
    if (getClientProject) {
      setColumns(columnsFromBackend);
    }
    // eslint-disable-next-line
  }, [getClientProject]);

  useEffect(() => {
    setColumns(columnsFromBackend);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchClientProject) {
      setColumns({
        // eslint-disable-next-line
        ["Backlog"]: {
          name: "Backlog",
          items: searchClientProject.Backlog
            ? searchClientProject.Backlog.items
            : [],
        },
        // eslint-disable-next-line
        ["Script"]: {
          name: "Script",
          items: searchClientProject.Script
            ? searchClientProject.Script.items
            : [],
        },
        // eslint-disable-next-line
        ["Voice"]: {
          name: "Voice",
          items: searchClientProject.Voice
            ? searchClientProject.Voice.items
            : [],
        },
        // eslint-disable-next-line
        ["Video"]: {
          name: "Video",
          items: searchClientProject.Video
            ? searchClientProject.Video.items
            : [],
        },
        // eslint-disable-next-line
        ["Comp"]: {
          name: "Completed",
          items: searchClientProject.Comp ? searchClientProject.Comp.items : [],
        },
        // eslint-disable-next-line
        ["Revision"]: {
          name: "Revision",
          items: searchClientProject.Revision
            ? searchClientProject.Revision.items
            : [],
        },
        // eslint-disable-next-line
        ["Ready"]: {
          name: "Ready to upload",
          items: searchClientProject.Ready
            ? searchClientProject.Ready.items
            : [],
        },
        // eslint-disable-next-line
        ["Published"]: {
          name: "Published",
          items: searchClientProject.Published
            ? searchClientProject.Published.items
            : [],
        },
        // eslint-disable-next-line
        ["Archived"]: {
          name: "Archived",
          items: searchClientProject.Archived
            ? searchClientProject.Archived.items
            : [],
        },
      });
    } else {
      setColumns({
        // eslint-disable-next-line
        ["Backlog"]: {
          name: "Backlog",
          items: [],
        },
        // eslint-disable-next-line
        ["Script"]: {
          name: "Script",
          items: [],
        },
        // eslint-disable-next-line
        ["Voice"]: {
          name: "Voice",
          items: [],
        },
        // eslint-disable-next-line
        ["Video"]: {
          name: "Video",
          items: [],
        },
        // eslint-disable-next-line
        ["Comp"]: {
          name: "Completed",
          items: [],
        },
        // eslint-disable-next-line
        ["Revision"]: {
          name: "Revision",
          items: [],
        },
        // eslint-disable-next-line
        ["Ready"]: {
          name: "Ready to upload",
          items: [],
        },
        // eslint-disable-next-line
        ["Published"]: {
          name: "Published",
          items: [],
        },
        // eslint-disable-next-line
        ["Archived"]: {
          name: "Archived",
          items: [],
        },
      });
    }
  }, [searchClientProject]);

  const addProject = (newTask) => {
    if (newTask !== "") {
      const updateData = columns["Backlog"];
      const items = [...updateData.items];
      for (const newP of newTask) {
        items.push({ id: uuidv4(), project_name: newP });
      }
      setColumns({
        ...columns,
        // eslint-disable-next-line
        ["Backlog"]: {
          ...updateData,
          items: items,
        },
      });
      for (const newp of newTask) {
        var obj = {
          project_name: newp,
          channel_id: data,
        };
        dispatch(addClientProjectData(obj));
      }
    }
  };

  return (
    <>
      <div className="board-container">
        <>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <Column
                columnData={column}
                id={columnId}
                key={columnId}
                colors={headerColors[index]}
                addProject={addProject}
              />
            );
          })}
        </>
      </div>
    </>
  );
};

export default Board;
