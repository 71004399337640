import React, { useEffect, useState, memo } from "react";
import Column from "./Column";
import { DragDropContext } from "react-beautiful-dnd";
import { headerColors } from "../../../../Common/kanban";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  addNewProject,
  // getProjectCount,
  getAllProject,
  // getBacklogProjects,
  // getScriptProjects,
  // getVoiceProjects,
  // getVideoProjects,
  // getCompProjects,
  // getReadyProjects,
  // getPublishedProjects,
  // getRevisionProjects,
} from "../../../../Feature/Admin/ChannelManagement/kanban/projectSlice";
import { updateProjectPhase } from "../../../../Feature/Admin/ChannelManagement/kanban/updateSlice";
import WarningModal from "./WarningModal";
import { useLocation } from "react-router-dom";
import Loaders from "../../../../Components/Loaders/Loaders";
import UpgradeModal from "../../../../Common/UpgradeModal";

function Board({ state }) {
  // let { id } = useParams();
  const location = useLocation();
  const data = location.state?.channel_id;
  const dispatch = useDispatch();
  const {
    backlogProjects,
    scriptProjects,
    voiceProjects,
    videoProjects,
    compProjects,
    readyProjects,
    publishedProjects,
    revisionProjects,
    count,
    addProjectSuccess,
    isLoading,
    addProjectError,
  } = useSelector((state) => state.projects);
  const { updatePhaseError, searchProjectSuccess } = useSelector(
    (state) => state.updateProject
  );
  const { updateProjectSuccess, deleteProjectSuccess } = useSelector(
    (state) => state.updateProjectData
  );
  const [columns, setColumns] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [upgrade, setUpgrade] = useState(false);
  // const [isProjectLoading, setIsProjectLoading] = useState("false");

  const handleClose = () => setShowWarning(!showWarning);
  const handleShow = () => setShowWarning(true);

  useEffect(() => {
    if (state === "activeTab") {
      dispatch(getAllProject(data));
      // dispatch(getProjectCount(data));
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (deleteProjectSuccess || updateProjectSuccess) {
      dispatch(getAllProject(data));
      // dispatch(getProjectCount(data));
      // dispatch(getBacklogProjects(data));
      // dispatch(getScriptProjects(data));
      // dispatch(getVoiceProjects(data));
      // dispatch(getVideoProjects(data));
      // dispatch(getCompProjects(data));
      // dispatch(getRevisionProjects(data));
      // dispatch(getReadyProjects(data));
      // dispatch(getPublishedProjects(data));
    }
    // eslint-disable-next-line
  }, [deleteProjectSuccess, updateProjectSuccess]);

  const columnsFromBackend = {
    // eslint-disable-next-line
    ["Backlog"]: {
      name: "Backlog",
      items: backlogProjects,
      // count: count.backlog,
    },
    // eslint-disable-next-line
    ["Script"]: {
      name: "Script",
      items: scriptProjects,
      // count: count.script,
    },
    // eslint-disable-next-line
    ["Voice"]: {
      name: "Voice",
      items: voiceProjects,
      // count: count.voice,
    },
    // eslint-disable-next-line
    ["Video"]: {
      name: "Video",
      items: videoProjects,
      // // count: count.video,
    },
    // eslint-disable-next-line
    ["Comp"]: {
      name: "Completed",
      items: compProjects,
      // // count: count.completed,
    },
    // eslint-disable-next-line
    ["Revision"]: {
      name: "Revision",
      items: revisionProjects,
      // // count: count.revision,
    },
    // eslint-disable-next-line
    ["Ready"]: {
      name: "Ready to upload",
      items: readyProjects,
      // // count: count.tobe,
    },
    // eslint-disable-next-line
    ["Published"]: {
      name: "Published",
      items: publishedProjects,
      // // count: count.uploaded,
    },
    // eslint-disable-next-line
    // ["Archived"]: {
    //   name: "Archived",
    //   items: archiveProjects,
    //   count: count.archive,
    // },
  };

  useEffect(() => {
    if (isLoading) {
      setColumns({
        // eslint-disable-next-line
        ["Backlog"]: {
          name: "Backlog",
          items: [],
          // count: count.Backlog,
        },
        // eslint-disable-next-line
        ["Script"]: {
          name: "Script",
          items: [],
          // // count: count.Script,
        },
        // eslint-disable-next-line
        ["Voice"]: {
          name: "Voice",
          items: [],
          // // count: count.Voice,
        },
        // eslint-disable-next-line
        ["Video"]: {
          name: "Video",
          items: [],
          // // count: count.Video,
        },
        // eslint-disable-next-line
        ["Comp"]: {
          name: "Completed",
          items: [],
          // // count: count.Comp,
        },
        // eslint-disable-next-line
        ["Revision"]: {
          name: "Revision",
          items: [],
          // // count: count.Revision,
        },
        // eslint-disable-next-line
        ["Ready"]: {
          name: "Ready to upload",
          items: [],
          // // count: count.Ready,
        },
        // eslint-disable-next-line
        ["Published"]: {
          name: "Published",
          items: [],
          // // count: count.Published,
        },
        // eslint-disable-next-line
        // ["Archived"]: {
        //   name: "Archived",
        //   items: [],
        //   // // count: count.Archived,
        // },
      });
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (backlogProjects) {
      setColumns((prevState) => ({
        ...prevState,
        // eslint-disable-next-line
        ["Backlog"]: {
          name: "Backlog",
          items: backlogProjects,
          // count: count.backlog,
        },
      }));
    }
    if (scriptProjects) {
      setColumns((prevState) => ({
        ...prevState,
        // eslint-disable-next-line
        ["Script"]: {
          name: "Script",
          items: scriptProjects,
          // count: count.script,
        },
      }));
    }
    if (voiceProjects) {
      setColumns((prevState) => ({
        ...prevState,
        // eslint-disable-next-line
        ["Voice"]: {
          name: "Voice",
          items: voiceProjects,
          // count: count.voice,
        },
      }));
    }
    if (videoProjects) {
      setColumns((prevState) => ({
        ...prevState,
        // eslint-disable-next-line
        ["Video"]: {
          name: "Video",
          items: videoProjects,
          // count: count.video,
        },
      }));
    }
    if (compProjects) {
      setColumns((prevState) => ({
        ...prevState,
        // eslint-disable-next-line
        ["Comp"]: {
          name: "Comp",
          items: compProjects,
          // count: count.completed,
        },
      }));
    }
    if (revisionProjects) {
      setColumns((prevState) => ({
        ...prevState,
        // eslint-disable-next-line
        ["Revision"]: {
          name: "Revision",
          items: revisionProjects,
          // count: count.revision,
        },
      }));
    }
    if (readyProjects) {
      setColumns((prevState) => ({
        ...prevState,
        // eslint-disable-next-line
        ["Ready"]: {
          name: "Ready To Upload",
          items: readyProjects,
          // count: count.tobe,
        },
      }));
    }
    if (publishedProjects) {
      setColumns((prevState) => ({
        ...prevState,
        // eslint-disable-next-line
        ["Published"]: {
          name: "Published",
          items: publishedProjects,
          // count: count.uploaded,
        },
      }));
    }

    // eslint-disable-next-line
  }, [
    backlogProjects,
    scriptProjects,
    voiceProjects,
    videoProjects,
    compProjects,
    revisionProjects,
    readyProjects,
    publishedProjects,
  ]);

  //console.log(columns);

  useEffect(() => {
    if (updatePhaseError) {
      setColumns(columnsFromBackend);
      setErrorMsg(
        "This project can't be moved as mandatory fields are missing"
      );
      handleShow();
    }
    // eslint-disable-next-line
  }, [updatePhaseError]);

  useEffect(() => {
    if (addProjectError) {
      setColumns(columnsFromBackend);
      setUpgrade(true);
    }
    // eslint-disable-next-line
  }, [addProjectError]);

  useEffect(() => {
    if (searchProjectSuccess) {
      if (
        searchProjectSuccess.Backlog ||
        searchProjectSuccess.Script ||
        searchProjectSuccess.Voice ||
        searchProjectSuccess.Video ||
        searchProjectSuccess.Comp ||
        searchProjectSuccess.Revision ||
        searchProjectSuccess.Ready ||
        searchProjectSuccess.Published
        // searchProjectSuccess.Archived
      ) {
        setColumns({
          // eslint-disable-next-line
          ["Backlog"]: {
            name: "Backlog",
            items: searchProjectSuccess.Backlog.items,
            // count: count.Backlog,
          },
          // eslint-disable-next-line
          ["Script"]: {
            name: "Script",
            items: searchProjectSuccess.Script.items,
            // count: count.Script,
          },
          // eslint-disable-next-line
          ["Voice"]: {
            name: "Voice",
            items: searchProjectSuccess.Voice.items,
            // count: count.Voice,
          },
          // eslint-disable-next-line
          ["Video"]: {
            name: "Video",
            items: searchProjectSuccess.Video.items,
            // count: count.Video,
          },
          // eslint-disable-next-line
          ["Comp"]: {
            name: "Completed",
            items: searchProjectSuccess.Comp.items,
            // count: count.Comp,
          },
          // eslint-disable-next-line
          ["Revision"]: {
            name: "Revision",
            items: searchProjectSuccess.Revision.items,
            // count: count.Revision,
          },
          // eslint-disable-next-line
          ["Ready"]: {
            name: "Ready to upload",
            items: searchProjectSuccess.Ready.items,
            // count: count.Ready,
          },
          // eslint-disable-next-line
          ["Published"]: {
            name: "Published",
            items: searchProjectSuccess.Published.items,
            // count: count.Published,
          },
          // eslint-disable-next-line
          // ["Archived"]: {
          //   name: "Archived",
          //   items: searchProjectSuccess.Archived.items,
          //   count: count.Archived,
          // },
        });
      } else {
        setColumns({
          // eslint-disable-next-line
          ["Backlog"]: {
            name: "Backlog",
            items: [],
            // count: count.Backlog,
          },
          // eslint-disable-next-line
          ["Script"]: {
            name: "Script",
            items: [],
            // count: count.Script,
          },
          // eslint-disable-next-line
          ["Voice"]: {
            name: "Voice",
            items: [],
            // count: count.Voice,
          },
          // eslint-disable-next-line
          ["Video"]: {
            name: "Video",
            items: [],
            // count: count.Video,
          },
          // eslint-disable-next-line
          ["Comp"]: {
            name: "Completed",
            items: [],
            // count: count.Comp,
          },
          // eslint-disable-next-line
          ["Revision"]: {
            name: "Revision",
            items: [],
            // count: count.Revision,
          },
          // eslint-disable-next-line
          ["Ready"]: {
            name: "Ready to upload",
            items: [],
            // count: count.Ready,
          },
          // eslint-disable-next-line
          ["Published"]: {
            name: "Published",
            items: [],
            // count: count.Published,
          },
          // eslint-disable-next-line
          // ["Archived"]: {
          //   name: "Archived",
          //   items: [],
          //   count: count.Archived,
          // },
        });
      }
    }
  }, [searchProjectSuccess, count]);

  const onDragEnd = (result) => {
    // console.log(columns, result);
    if (!result.destination) return;
    const { source, destination } = result;
    // console.log(source, destination);
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      // console.log("data", removed, destination);
      var obj = {
        project_id: removed.project_id,
        flag: "subchannel",
      };
      if (destination.droppableId === "Backlog") {
        obj.stage_id = 1;
        dispatch(updateProjectPhase(obj));
      }
      if (destination.droppableId === "Script") {
        obj.stage_id = 2;
        dispatch(updateProjectPhase(obj));
      }
      if (destination.droppableId === "Voice") {
        obj.stage_id = 3;
        dispatch(updateProjectPhase(obj));
      }
      if (destination.droppableId === "Video") {
        obj.stage_id = 4;
        dispatch(updateProjectPhase(obj));
      }
      if (destination.droppableId === "Comp") {
        obj.stage_id = 5;
        dispatch(updateProjectPhase(obj));
      }
      if (destination.droppableId === "Ready") {
        obj.stage_id = 6;
        dispatch(updateProjectPhase(obj));
      }
      if (destination.droppableId === "Published") {
        obj.stage_id = 7;
        dispatch(updateProjectPhase(obj));
      }
      // if (destination.droppableId === "Archived") {
      //   obj.stage_id = 8;
      //   dispatch(updateProjectPhase(obj));
      // }
      if (destination.droppableId === "Revision") {
        obj.stage_id = 9;
        dispatch(updateProjectPhase(obj));
      }
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const addProject = (newTask) => {
    const updateData = columns["Backlog"];
    const items = [...updateData.items];
    for (const newP of newTask) {
      items.push({ id: uuidv4(), project_name: newP });
    }
    setColumns({
      ...columns,
      // eslint-disable-next-line
      ["Backlog"]: {
        ...updateData,
        items: items,
      },
    });
    for (const newp of newTask) {
      var obj = {
        project_name: newp,
        channel_id: data,
      };
      dispatch(addNewProject(obj));
    }
  };

  useEffect(() => {
    if (addProjectSuccess) {
      // console.log(addProjectSuccess)
      if (typeof addProjectSuccess === "object") {
        if (addProjectSuccess.data) {
          const updateData = columns["Backlog"];
          // console.log(updateData);
          const items = [...updateData.items];
          const newData = items.map((obj) => {
            // console.log(obj.project_id, addProjectSuccess.data)
            // console.log(obj)
            if (
              obj.project_id === undefined &&
              obj.project_name === addProjectSuccess.data.project_name
            ) {
              return {
                ...obj,
                project_name: addProjectSuccess.data.project_name,
                project_id: addProjectSuccess.data.project_id,
              };
            }
            return obj;
          });
          // console.log(newData)
          setColumns({
            ...columns,
            // eslint-disable-next-line
            ["Backlog"]: {
              ...updateData,
              items: newData,
            },
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [addProjectSuccess]);

  const close = () => setUpgrade(false);

  return (
    <>
      <div className="board-container">
        <>
          {isLoading === true ? (
            <>
              <div style={{ marginLeft: "20%" }}>
                <Loaders color="#5f48ea" />
              </div>
            </>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              {Object.entries(columns).map(([columnId, column], index) => {
                // console.log(columnId, column, index);
                return (
                  <Column
                    columnData={column}
                    id={columnId}
                    key={columnId}
                    colors={headerColors[index]}
                    addProject={addProject}
                    state={state}
                  />
                );
              })}
            </DragDropContext>
          )}
        </>
      </div>
      <WarningModal show={showWarning} toggle={handleClose} msg={errorMsg} />
      <UpgradeModal show={upgrade} handleClose={close} />
    </>
  );
}

export default memo(Board);
